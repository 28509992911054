import React from 'react';
import {RequestActivation} from "../../../../../models/RequestActivation";
import {Card, Collapse, Descriptions, DescriptionsProps, Table, Typography} from "antd";
import {CalendarOutlined, ReadOutlined} from "@ant-design/icons";
import {Activation} from "../../../../../models/Activation";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";

interface InformationTabProps {
    requestActivation: RequestActivation
}

function InformationTab({ requestActivation }: InformationTabProps) {
    let idx = 1;
    const getData = (): DescriptionsProps['items'] => {
        return [
            {
                key: idx++,
                label: 'Marca',
                children: <span>{ requestActivation.brand?.name }</span>,
                span: 24
            },
            {
                key: idx++,
                label: 'Servicio',
                children: <span>{ requestActivation.activationService?.name }</span>,
                span: 24
            },
            {
                key: idx++,
                label: 'Objetivo de la activación',
                children: <span>{ requestActivation.activationGoal?.name }</span>,
                span: 24
            },
            {
                key: idx++,
                label: 'Dinámica especial',
                children: <span>{ requestActivation.requiresSpecialDynamic ? 'Si' : 'No' }</span>,
                span: 24
            },
            {
                key: idx++,
                label: 'Tipo de activación',
                children: <span>{ requestActivation.requestActivationType?.name }: { requestActivation.requestActivationSubType?.name }</span>,
                span: 24
            },
            {
                key: idx++,
                label: 'Materiales promocionales',
                children: <span>{ requestActivation.promotionalMaterial?.name }</span>,
                span: 24
            },
            {
                key: idx++,
                label: 'Equipamiento extra',
                children: <span>{ requestActivation.equipmentAddon?.name }</span>,
                span: 24
            },
        ];
    }

    return (
        <div className="request-activation-details-information-tab">
            <Descriptions
                bordered
                size="middle"
                items={getData()}
            />

            <Collapse items={[
                {
                    key: '1',
                    label: <span><ReadOutlined /> Observaciones adicionales</span>,
                    children: (
                        <div style={{ width: '100%'}}>
                            { requestActivation.additionalInfo1 || '- Sin Observaciones -' }
                        </div>
                    )
                }
            ]} defaultActiveKey={['1']} />

            <Collapse items={[
                {
                    key: '1',
                    label: <span><CalendarOutlined /> Ver programación</span>,
                    children: (
                        <div style={{ width: '100%'}}>
                            <Table
                                <Activation>
                                dataSource={requestActivation.activations}
                                pagination={false}
                                size="small"
                                scroll={{ x: 768 }}
                                rowKey={(record) => { return record.id }}
                            >
                                <Column width={320} title="Cadena" ellipsis key="businessChain" render={(row: Activation) => (
                                    <>{ row.store!.businessChain!.name }</>
                                )}/>

                                <Column width={320} title="PDV" ellipsis key="store" render={(row: Activation) => (
                                    <>{ row.store!.name }</>
                                )}/>

                                <Column width={180} title="Fechas" key="dates" render={(row: Activation) => (
                                    <>{ `${dayjs(row.dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')} - ${dayjs(row.dates[row.dates.length - 1], 'YYYY-MM-DD').format('DD/MM/YYYY')}` }</>
                                )}/>

                                <Column width={60} align="center"  title="Días" key="days" render={(row: Activation) => (
                                    <>{ row.dates.length }</>
                                )}/>

                                <Column width={320} title="Horarios" key="schedules" render={(row: Activation) => (
                                    <>{ row.availableSchedule?.label }</>
                                )}/>

                                <Column width={110} align="center" title="Colaboradores" key="numberOfCollaborators" render={(row: Activation) => (
                                    <>{ row.numberOfCollaborators }</>
                                )}/>

                                <Column width={120} title="Subtotal" align="center" key="subtotalAmount" render={(row: Activation) => (
                                    <>{ Number(row.totalAmount).toFixed(2) + ' ' + requestActivation.currency!.code }</>
                                )}/>
                            </Table>
                        </div>
                    )
                }
            ]} />

        </div>
    );
}

export default InformationTab;
