import React from 'react';
import {RequestActivation} from "../../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../../config/ThemeDashboardLayoutConfig";
import {
    Descriptions,
    DescriptionsProps,
    Tag,
    Typography
} from "antd";
import { ReactComponent as UserIcon } from "../../../../../assets/icons/user.svg";
import { ReactComponent as MailIcon } from "../../../../../assets/icons/mail.svg";
import { ReactComponent as CredentialIcon } from "../../../../../assets/icons/credential.svg";
import {Contact} from "../../../../../models/Contact";
import {ExclamationCircleOutlined, MoreOutlined} from "@ant-design/icons";
import StatusIndicator from "../../../../../components/StatusIndicator/StatusIndicator";

interface LeftSidebarProps {
    requestActivation: RequestActivation;
    contacts: Contact[];
}

function LeftSidebar({ requestActivation, contacts }: LeftSidebarProps) {
    const themeConfig = useThemeLayoutConfig();

    const getFirstAvailableContact = () => {
        const mainContact = contacts.filter((record) => {
            return record.isMainContact;
        });

        if(mainContact.length > 0) {
            return mainContact[0];
        }

        return contacts.length > 0 ? contacts[0] : null;
    }

    const getContactEmail = () => {
        return getFirstAvailableContact() ? getFirstAvailableContact()?.email : '----';
    }

    const getContactName = () => {
        return getFirstAvailableContact() ? `${getFirstAvailableContact()?.name} ${getFirstAvailableContact()?.lastName}` : '----';
    }

    const getContactPhoneNumber = () => {
        return getFirstAvailableContact() ? `${getFirstAvailableContact()?.dialCode} ${getFirstAvailableContact()?.phoneNumber}` : '----';
    }

    const getContactJobTitle = () => {
        return getFirstAvailableContact() ? getFirstAvailableContact()?.jobTitle : '----';
    }

    const getTask = (): React.ReactNode | string => {
        if(['CONFIRMED', 'REFUSED_PURCHASE_ORDER'].includes(requestActivation.requestActivationStatusId)) {
            return (
                <Tag icon={<ExclamationCircleOutlined />} color="processing">
                    Cargar orden de compra
                </Tag>
            );
        }


        return <Tag color="default">No presenta ninguna tarea pendiente</Tag>;
    }

    const getItems = (): DescriptionsProps['items'] => {
        return [
            {
                label: 'Marca',
                children: <Typography style={{ color: themeConfig.token?.colorPrimary, fontWeight: 550 }}>{ requestActivation.brand?.name }</Typography>,
                span: 3
            },
            {
                label: 'Tipo de activación',
                children: requestActivation.requestActivationType?.name,
                span: 3
            },
            {
                label: 'Estado',
                children: (
                    <StatusIndicator text={requestActivation.requestActivationStatus!.publicName} color={requestActivation.requestActivationStatus!.color}/>
                ),
                span: 3
            },
            {
                label: 'Tarea pendiente',
                children: (
                    <>
                        {getTask()}
                    </>
                ),
                span: 3
            },
            {
                label: 'Contacto',
                children: (
                    <div>
                        <span className="request-activation-details-left-sidebar">
                            <UserIcon style={{ fill: themeConfig.token?.colorPrimary }}  /> <Typography>{ getContactName() }</Typography>
                        </span>

                        <span className="request-activation-details-left-sidebar">
                            <CredentialIcon style={{ fill: themeConfig.token?.colorPrimary }} /> <Typography>{ getContactJobTitle() }</Typography>
                        </span>

                        <span className="request-activation-details-left-sidebar">
                            <MailIcon style={{ fill: themeConfig.token?.colorPrimary }} /> <Typography>{ getContactEmail() }</Typography>
                        </span>
                    </div>
                ),
                span: 3
            }
        ];
    }

    return (
        <div style={{ width: '100%' }}>
            <Descriptions
                items={getItems()}
                size="default"
                style={{ width: '100%' }}
                layout="vertical"
            />
        </div>
    );
}

export default LeftSidebar;
