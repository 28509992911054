import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Dropdown, MenuProps, message, Modal, Row, Table, TableProps, Tag} from "antd";
import {TableParams} from "../../../models/TableParams";
import {PAGE_SIZE} from "../../../config/Constants";
import {SearchProps} from "antd/es/input";
import {
    DeleteOutlined, ExclamationCircleOutlined,
    EyeOutlined,
    MoreOutlined,
    PlusOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import Column from "antd/es/table/Column";
import Layout from "../../../components/Layout/Layout";
import {OperationReportService} from "../../../services/OperationReportService";
import {OperationReport} from "../../../models/OperationReport";
import dayjs from "dayjs";
import NewOperationReportModal from "./components/NewOperationReportModal";
import {CompanyService} from "../../../services/CompanyService";
import {Company} from "../../../models/Company";
import {OperationTag} from "../../../models/OperationTag";
import {OperationTagService} from "../../../services/OperationTagService";
import OperationReportDetailModal from "./components/OperationReportDetailModal";

function OperationReports() {
    const [modal, contextModalHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<OperationReport[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: undefined,
        sortOrder: undefined
    });

    /* Filters */
    const [term, setTerm] = useState('');

    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<OperationReport | undefined>(undefined);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [operationTags, setOperationTags] = useState<OperationTag[]>([]);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const [
            companyResponse,
            operationTagResponse
        ] = await Promise.all([
            CompanyService.getAll(),
            OperationTagService.getAll(),
            fetchData()
        ]);

        if(companyResponse.success && operationTagResponse.success) {
            setCompanies(companyResponse.data);
            setOperationTags(operationTagResponse.data);
            setLoading(false);
        }else {
            if(!companyResponse.success) {
                const error = companyResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los clientes, por favor inténtalo nuevamente.', 3.5);
            }

            if(!operationTagResponse.success) {
                const error = operationTagResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener las etiquetas disponibles, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);

        const termFilter = term.trim() || undefined;

        const datasourceResponse = await OperationReportService.getAllByFiltersAndPaginate({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: termFilter,
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<OperationReport>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getDropdownMenu = (row: OperationReport): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];

        menus.push({
            key: '1',
            label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver detalle</span>,
            onClick: () => { showDetailModalOpen(row); }
        });

        menus.push({
            key: '2',
            danger: true,
            label: <span><DeleteOutlined style={{ marginRight: '8px' }}/>Eliminar</span>,
            onClick: () => { deleteReport(row); }
        });

        return menus;
    }

    const deleteReport = async (row: OperationReport) => {
        modal.confirm({
            title: 'Confirmación',
            icon: <ExclamationCircleOutlined />,
            content: <span>¿Estás seguro que deseas eliminar el reporte: <b>{row.name}</b>?</span>,
            okText: 'SI',
            cancelText: 'NO',
            onOk: async () => {
                setTableLoading(true);

                const response = await OperationReportService.delete(row.id);

                if(response.success) {
                    await fetchData(1);
                    messageApi.success(`Se eliminó satisfactoriamente el reporte con ID: ${row.id}.`);
                }else {
                    const error = response.data;
                    messageApi.error(error.message as string || 'Hubo un error al intentar obtener eliminar el reporte, por favor inténtalo nuevamente.', 3.5);
                    setTableLoading(false);
                }
            }
        });
    }

    const showDetailModalOpen = (row: OperationReport) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const showNewModalOpen = () => {
        if(!tableLoading) {
            setIsNewModalOpen(true);
        }
    }

    const closeNewModalOpen = () => {
        setIsNewModalOpen(false);
    }

    const closeDetailClientModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const createNewOperationReport = async (name: string, companyId: number, brandId: number, tags: string[], url: string) => {
        const response = await OperationReportService.create({
            name, companyId, brandId, tags, url
        });

        if(response.success) {
            const [
                operationTagResponse
            ] = await Promise.all([
                OperationTagService.getAll(),
                fetchData(1)
            ]);
            messageApi.success(<span>Se creó el reporte con nombre: <b>{name}</b> con ID <b>{response.data.createdId}</b> de manera satisfactoria.</span>, 3.5);
            setIsNewModalOpen(false);

            if(operationTagResponse.success) {
                setOperationTags(operationTagResponse.data);
            }else {
                messageApi.error(operationTagResponse.data.message as string || 'Hubo un error al intentar obtener las etiquetas, por favor inténtalo nuevamente.', 3.5);
            }
        }else {
            messageApi.error(response.data.message as string || 'Hubo un error al intentar crear el reporte, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const updateOperationReport = async (id: number, name: string, brandId: number, tags: string[], url: string) => {
        const response = await OperationReportService.update(id, {
            name, brandId, tags, url
        });

        if(response.success) {
            const [
                operationTagResponse
            ] = await Promise.all([
                OperationTagService.getAll(),
                fetchData(1)
            ]);
            messageApi.success(<span>Se actualizó el reporte con nombre: <b>{name}</b> con ID <b>{id}</b> de manera satisfactoria.</span>, 3.5);
            setIsDetailModalOpen(false);

            if(operationTagResponse.success) {
                setOperationTags(operationTagResponse.data);
            }else {
                messageApi.error(operationTagResponse.data.message as string || 'Hubo un error al intentar obtener las etiquetas, por favor inténtalo nuevamente.', 3.5);
            }
        }else {
            messageApi.error(response.data.message as string || 'Hubo un error al intentar actualizar el reporte, por favor inténtalo nuevamente.', 3.5);
        }
    }

    return (
        <Layout breadcrumb={[
            { title: <span><SettingOutlined /> Ajustes</span> },
            { title: 'Reportes' }
        ]}
        >
            { contextModalHolder }
            { contextHolder }

            <Card
                loading={loading}
            >
                <div className="filter-container">
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                            <label className="filter-search-container">Filtrar por: <Search placeholder="Nombre, cliente o marca" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                            <Button type="primary" onClick={showNewModalOpen}  disabled={tableLoading}><PlusOutlined /> Crear reporte</Button>
                        </Col>
                    </Row>
                </div>
            </Card>

            <Card
                style={{ marginTop: '18px' }}
                loading={loading}
            >
                <Table
                    <OperationReport>
                    dataSource={dataSource}
                    bordered
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ y: `CALC(100VH - 390px)`, x: 768 }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column width={80} fixed="left" align="center" title="ID" dataIndex="id" key="id"/>

                    <Column width={320} ellipsis title="Nombre" key="name" render={(row: OperationReport) => (
                        <>{row.name}</>
                    )}/>

                    <Column width={320} ellipsis title="Cliente" key="company" render={(row: OperationReport) => (
                        <>{row.company!.name}</>
                    )}/>

                    <Column width={320} ellipsis title="Marca" key="brand" render={(row: OperationReport) => (
                        <>{row.brand!.name}</>
                    )}/>

                    <Column width={320} ellipsis title="Etiquetas" key="tags" render={(row: OperationReport) => (
                        row.operationTags!.map((record) => {
                            return (
                                <Tag>{record.name}</Tag>
                            );
                        })
                    )}/>

                    <Column align="center" title="Fecha de creación" key="createdAt" render={(row: OperationReport) => (
                        <span title={dayjs(row.createdAt).format('DD/MM/YYYY hh:mm A')}>{dayjs(row.createdAt).format('DD/MM/YYYY')}</span>
                    )} sorter={true} defaultSortOrder="descend" width={150} hidden/>

                    <Column width={60} fixed="right" align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Card>

            {/* Modals */}
            <NewOperationReportModal
                isOpen={isNewModalOpen}
                handleModalCancel={closeNewModalOpen}
                submit={createNewOperationReport}
                companies={companies}
                operationTags={operationTags}
            />

            {
                selectedRow && (
                    <OperationReportDetailModal
                        id={selectedRow.id}
                        isOpen={isDetailModalOpen}
                        handleModalCancel={closeDetailClientModalOpen}
                        submit={updateOperationReport}
                        operationTags={operationTags}
                    />
                )
            }
        </Layout>
    );
}

export default OperationReports;
