import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Row, Select,
} from "antd";
import validator from 'validator';
import {ItemInterface} from "../../../../models/ItemInterface";
import {BrandService} from "../../../../services/BrandService";
import {OperationTag} from "../../../../models/OperationTag";
import TextArea from "antd/es/input/TextArea";
import {OperationReportService} from "../../../../services/OperationReportService";

interface OperationReportDetailModalProps {
    id: number;
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (
        id: number, name: string, brandId: number, tags: string[], url: string
    ) => void;
    operationTags: OperationTag[];
}

function OperationReportDetailModal({ id, isOpen, handleModalCancel, submit, operationTags }: OperationReportDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState<ItemInterface[]>([]);
    const [tags, setTags] = useState<ItemInterface[]>([]);
    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        if(isOpen) {
            setLoading(true);
            fetchData();
        }else {
            form.resetFields();
            setBrands([]);
            setTags(operationTags.map((record) => {
                return {
                    value: record.name,
                    label: record.name,
                };
            }));
            setCompanyName('');
        }
    }, [isOpen]);

    const fetchData = async () => {
        const response = await OperationReportService.findById(id);

        if(response.success) {
            const localOperationReport = response.data;

            const brandResponse = await BrandService.getByCompany(response.data.companyId);

            if(brandResponse.success) {
                setBrands(brandResponse.data.map((record) => {
                    return {
                        value: record.id,
                        label: record.name
                    };
                }));

                setCompanyName(localOperationReport?.company?.name || '');

                form.setFieldValue('name', localOperationReport.name);
                form.setFieldValue('url', localOperationReport.url);
                form.setFieldValue('brand', localOperationReport.brandId);
                form.setFieldValue('tags', (localOperationReport.operationTags || []).map((record) => {
                    return record.name
                }));

                setLoading(false);
            }else {
                const error = brandResponse.data;
                messageApi.error(error.message as string || `Hubo un error al intentar obtener las marcas, por favor inténtalo nuevamente.`, 3.5);
                handleModalCancel();
            }
        }else {
            const error = response.data;
            messageApi.error(error.message as string || `Hubo un error al intentar obtener el reporte, por favor inténtalo nuevamente.`, 3.5);
            handleModalCancel();
        }
    }


    const onFinish = async (values: any) => {
        setLoading(true);

        await submit(
            id,
            values.name,
            values.brand,
            values.tags,
            values.url
        );

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={`Detalles del reporte - ID: ${id}`}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={560}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre del reporte"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="url"
                                label="URL"
                                rules={[
                                    { required: true, message: 'Debes de ingresar el URL.' },
                                    {
                                        validator: (_, value) => {
                                            if (!value || validator.isURL(value, { require_protocol: true })) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Debes de ingresar un URL válido.'));
                                        },
                                    },
                                ]}
                                tooltip={'La URL siempre debe de iniciar por "http://" o "https://"'}
                            >
                                <TextArea rows={4} disabled={loading} placeholder="Ingrese el URL del reporte"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="tags"
                                label="Etiquetas"
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    tokenSeparators={[',']}
                                    options={tags}
                                    disabled={loading}
                                    placeholder="Seleeccione o ingresa las etiquetas relacionadas al reporte"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Cliente"
                            >
                                <Input readOnly type="text" disabled={loading} placeholder="Nombre del cliente" value={companyName}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="brand"
                                label="Marca"
                                rules={[{ required: true, message: 'Debes de seleccionar una marca' }]}
                            >
                                <Select
                                    options={brands}
                                    placeholder="-Seleccione la marca-"
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default OperationReportDetailModal;
