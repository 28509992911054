import React from 'react';
import {
    Descriptions,
    DescriptionsProps,
    Tag,
    Typography
} from "antd";
import {RequestActivation} from "../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../config/ThemeDashboardLayoutConfig";
import {RequestActivationStatus} from "../../../../models/RequestActivationStatus";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import StatusIndicator from "../../../../components/StatusIndicator/StatusIndicator";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone.svg";

interface AdminLeftSidebarProps {
    requestActivation: RequestActivation;
    requestActivationStatuses: RequestActivationStatus[];
}

function AdminLeftSidebar({ requestActivation, requestActivationStatuses }: AdminLeftSidebarProps) {
    const themeConfig = useThemeLayoutConfig();

    const findStatusById = (id: string) => {
        return requestActivationStatuses.find((record) => {
            return record.id === id;
        });
    }

    const getTask = (): React.ReactNode | string => {
        if(requestActivation.requestActivationStatusId === 'CREATED') {
            return (
                <Tag icon={<ExclamationCircleOutlined />} color="purple">
                    Cargar cotización
                </Tag>
            );
        }

        if(requestActivation.requestActivationStatusId === 'PURCHASE_ORDER_TO_REVIEW') {
            return (
                <Tag icon={<ExclamationCircleOutlined />} color="processing">
                    Validar orden de compra
                </Tag>
            );
        }


        return <Tag color="default">No presenta ninguna tarea pendiente</Tag>;
    }

    const getItems = (): DescriptionsProps['items'] => {
        return [
            {
                label: 'Compañía',
                children: <Typography style={{ color: themeConfig.token?.colorPrimary, fontWeight: 550 }}>{ requestActivation.company?.name }</Typography>,
                span: 3
            },
            {
                label: 'Tipo de activación',
                children: requestActivation.requestActivationType?.name,
                span: 3
            },
            {
                label: 'Fase',
                children: (
                    <StatusIndicator text={requestActivation.requestActivationStatus!.privateName} color={requestActivation.requestActivationStatus!.privateColor}/>
                ),
                span: 3
            },
            {
                label: 'Tarea pendiente:',
                children: (
                    <>
                        {getTask()}
                    </>
                ),
                span: 3
            },
            {
                label: 'Contacto',
                children: (
                    <div>
                        <span className="request-activation-details-left-sidebar">
                            <UserIcon style={{ fill: themeConfig.token?.colorPrimary, width: '34px', height: '26px' }}  /> <Typography>{ `${requestActivation.createdByUser?.name} ${requestActivation.createdByUser?.lastname}` }</Typography>
                        </span>

                        <span className="request-activation-details-left-sidebar">
                            <MailIcon style={{ fill: themeConfig.token?.colorPrimary, width: '34px', height: '26px' }} /> <Typography>{ requestActivation.createdByUser?.email }</Typography>
                        </span>

                        <span className="request-activation-details-left-sidebar">
                            <PhoneIcon style={{ fill: themeConfig.token?.colorPrimary, width: '34px', height: '26px' }} /> <Typography>{ `${requestActivation.createdByUser?.dialCode} ${requestActivation.createdByUser?.phone}` }</Typography>
                        </span>
                    </div>
                ),
                span: 3
            }
        ];
    }

    return (
        <div style={{ width: '100%' }}>
            <Descriptions
                items={getItems()}
                size="default"
                style={{ width: '100%' }}
                layout="vertical"
            />
        </div>
    );
}

export default AdminLeftSidebar;
