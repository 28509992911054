import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Row, Select,
} from "antd";
import validator from 'validator';
import {ItemInterface} from "../../../../models/ItemInterface";
import {Company} from "../../../../models/Company";
import {BrandService} from "../../../../services/BrandService";
import {OperationTag} from "../../../../models/OperationTag";
import TextArea from "antd/es/input/TextArea";

interface NewOperationReportModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (
        name: string, companyId: number, brandId: number, tags: string[], url: string
    ) => void;
    companies: Company[];
    operationTags: OperationTag[];
}

function NewOperationReportModal({ isOpen, handleModalCancel, submit, companies, operationTags }: NewOperationReportModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);
    const [brands, setBrands] = useState<ItemInterface[]>([]);
    const [tags, setTags] = useState<ItemInterface[]>([]);

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
            setSelectedCompanyId(null);
            setBrands([]);
            setTags(operationTags.map((record) => {
                return {
                    value: record.name,
                    label: record.name,
                };
            }));
        }
    }, [isOpen]);


    const onFinish = async (values: any) => {
        setLoading(true);
        await submit(
            values.name,
            values.company,
            values.brand,
            values.tags,
            values.url
        );

        setLoading(false);
    }

    const getCompanies = (): ItemInterface[] => {
        return companies.map((record) => {
            return {
                value:  record.id,
                label: record.name
            };
        });
    }

    const onChangeCompany = async (newCompanyId: number | null) => {
        form.setFieldValue('brand', null);
        setSelectedCompanyId(newCompanyId);
        setBrands([]);

        if(newCompanyId) {
            setLoading(true);

            const brandResponse = await BrandService.getByCompany(newCompanyId);

            if(brandResponse.success) {
                setBrands(brandResponse.data.map((record) => {
                    return {
                        value: record.id,
                        label: record.name
                    };
                }));
            }else {
                const error = brandResponse.data;
                messageApi.error(error.message as string || `Hubo un error al intentar obtener las marcas de la compañía con ID ${newCompanyId}, por favor inténtalo nuevamente.`, 3.5);
            }

            setLoading(false);
        }
    }

    return (
        <>
            {contextHolder}

            <Modal
                title="Crear reporte"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={560}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre del reporte"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="url"
                                label="URL"
                                rules={[
                                    { required: true, message: 'Debes de ingresar el URL.' },
                                    {
                                        validator: (_, value) => {
                                            if (!value || validator.isURL(value, { require_protocol: true })) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Debes de ingresar un URL válido.'));
                                        },
                                    },
                                ]}
                                tooltip={'La URL siempre debe de iniciar por "http://" o "https://"'}
                            >
                                <TextArea rows={4} disabled={loading} placeholder="Ingrese el URL del reporte"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="tags"
                                label="Etiquetas"
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    tokenSeparators={[',']}
                                    options={tags}
                                    disabled={loading}
                                    placeholder="Seleeccione o ingresa las etiquetas relacionadas al reporte"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="company"
                                label="Cliente"
                                rules={[{ required: true, message: 'Debes de seleccionar un cliente' }]}
                            >
                                <Select
                                    options={getCompanies()}
                                    placeholder="-Seleccione el cliente-"
                                    onChange={async (v) => {
                                        await onChangeCompany(v);
                                    }}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="brand"
                                label="Marca"
                                rules={[{ required: true, message: 'Debes de seleccionar una marca' }]}
                            >
                                <Select
                                    options={brands}
                                    placeholder="-Seleccione la marca-"
                                    disabled={loading || !selectedCompanyId}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewOperationReportModal;
