import React, {useState} from 'react';
import {Button, Card, Col, Collapse, Dropdown, MenuProps, message, Modal, Row, Space, Table, Typography} from "antd";
import {
    CalendarOutlined,
    ClockCircleOutlined,
    DislikeOutlined,
    DownloadOutlined,
    DownOutlined, ExclamationCircleOutlined, LikeOutlined, ReadOutlined,
    UploadOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import {RequestActivation} from "../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../config/ThemeDashboardLayoutConfig";
import {Schedule} from "../../../../models/Schedule";
import {FileService} from "../../../../services/FileService";
import {FunctionsHelper} from "../../../../utils/FunctionsHelper";
import {RequestActivationService} from "../../../../services/RequestActivationService";
import {Activation} from "../../../../models/Activation";
import Column from "antd/es/table/Column";

interface AdminGeneralDetailTabProps {
    requestActivation: RequestActivation;
    showUploadQuoteModal: () => void;
    showRefusedOcModalOpen: (row: RequestActivation) => void;
    fetchData: (reloadGrid?: boolean) => void;
    showRefusedStatusModalOpen: (row: RequestActivation) => void;
}

function AdminGeneralDetailTab({ requestActivation, showUploadQuoteModal, showRefusedOcModalOpen, fetchData, showRefusedStatusModalOpen }: AdminGeneralDetailTabProps) {
    const [modal, contextModalHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();
    const themeConfig = useThemeLayoutConfig();
    const [loadingQuoteFile, setLoadingQuoteFile] = useState(false);
    const [loadingOcFile, setLoadingOcFile] = useState(false);

    const getAllCollaborators = () => {
        return requestActivation.activations!.reduce((accumulator, currentActivation) => {
            return accumulator + currentActivation.numberOfCollaborators;
        }, 0);
    };

    const getFormattedDates = () => {
        const activations = requestActivation.activations!;

        let allDates: string[] = [];

        activations.forEach((activation) => {
            allDates = allDates.concat(activation.dates);
        });

        // @ts-ignore
        const dayJsDates = [...new Set(allDates)].map((date) => {
            return dayjs(date, 'YYYY-MM-DD');
        });

        const minDate = dayJsDates.reduce((a, b) => a.isBefore(b) ? a : b);
        const maxDate = dayJsDates.reduce((a, b) => a.isAfter(b) ? a : b);

        return `${minDate.format('DD/MM/YYYY')} - ${maxDate.format('DD/MM/YYYY')}`
    }

    const getUqSchedules = () => {
        const activations = requestActivation.activations!;
        const schedules: Schedule[] = [];

        activations.forEach((activation) => {
            const check = schedules.find((schedule) => {
                return schedule.id === activation.scheduleId;
            });

            if(!check) {
                schedules.push(activation.availableSchedule!);
            }
        });

        return schedules;
    }

    const getOcMenuProps = (): MenuProps['items'] => {
        if(requestActivation.requestActivationStatusId === 'PURCHASE_ORDER_TO_REVIEW') {
            return [
                {
                    label: 'Descargar',
                    key: '1',
                    icon: <DownloadOutlined/>,
                    onClick: downloadOcFile,
                    disabled: loadingOcFile

                },
                {
                    type: 'divider'
                },
                {
                    label: 'Validar',
                    key: '2',
                    icon: <LikeOutlined />,
                    onClick: confirmOC
                },
                {
                    label: 'Rechazar',
                    key: '3',
                    danger: true,
                    icon: <DislikeOutlined />,
                    onClick: () => { showRefusedOcModalOpen(requestActivation); }
                }
            ];
        }

        return [
            {
                label: 'Descargar',
                key: '1',
                icon: <DownloadOutlined/>,
                onClick: downloadOcFile,
                disabled: loadingOcFile
            },
        ];
    }

    const getFinalQuoteMenuProps = (): MenuProps['items'] => {
        return [
            {
                label: 'Subir cotización final',
                key: '1',
                icon: <UploadOutlined />,
                onClick: showUploadQuoteModal
            },
            {
                label: 'Rechazar solicitud',
                key: '2',
                danger: true,
                icon: <DislikeOutlined />,
                onClick: () => { refusedCurrentRequest(); }
            }
        ];
    }

    const downloadQuoteFile = async () => {
        if(!loadingQuoteFile) {
            setLoadingQuoteFile(true);

            const response = await FileService.getFile(requestActivation.finalQuote!.documentS3Key);

            if(response.success) {
                FunctionsHelper.openFileInOtherTab(response.data.fileBlob);
            }else {
                const error = response.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener el documento de cotización final, por favor inténtalo nuevamente.', 3.5);
            }

            setLoadingQuoteFile(false);
        }
    }

    const downloadOcFile = async () => {
        if(!loadingOcFile) {
            setLoadingOcFile(true);

            const response = await FileService.getFile(requestActivation.purchaseOrderDocumentS3Key!);

            if(response.success) {
                FunctionsHelper.openFileInOtherTab(response.data.fileBlob);
            }else {
                const error = response.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener el documento de orden de compra, por favor inténtalo nuevamente.', 3.5);
            }

            setLoadingOcFile(false);
        }
    }

    const confirmOC = () => {
        modal.confirm({
            title: 'Confirmación',
            icon: <ExclamationCircleOutlined />,
            content: <span>¿Estás seguro que deseas validar la <b>orden de compra</b> cargada por el cliente?</span>,
            okText: 'SI',
            cancelText: 'NO',
            onOk: async () => {
                const response = await RequestActivationService.updateStatusToApprovedPurchaseOrder(requestActivation.id);

                if(response.success) {
                    await fetchData(true);

                    messageApi.success(<span>Se aprobó orden de compra de la solicitud con N° <b>{requestActivation.id} de manera satisfactoria.</b></span>, 3.5);
                }else {
                    messageApi.error(response.data.message as string || 'Hubo un error al intentar aprobar la orden de compra, por favor inténtalo nuevamente.', 3.5);
                }
            }
        });
    }

    const refusedCurrentRequest = () => {
        showRefusedStatusModalOpen(requestActivation);
    }

    const getTaxRateFormatted = () => {
        return (requestActivation.requestTaxRate * 100).toFixed(2);
    }

    return (
        <div className="admin-request-activation-details-general-tab">
            { contextHolder }
            { contextModalHolder }
            <div className="admin-general-detail-class-container">
                <div className="admin-general-detail-class-children" style={{ borderLeft: `4px solid ${themeConfig.components!.Menu!.itemActiveBg}` }}>
                    <Typography><span style={{ fontWeight: 550 }}>Marca: </span>{ requestActivation.brand!.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Servicio: </span>{ requestActivation.activationService?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Objetivos de la activación: </span>{ requestActivation.activationGoal?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Dinámica especial: </span>{ requestActivation.requiresSpecialDynamic ? 'Si' : 'No' }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Tipo de activación: </span>{ requestActivation.requestActivationType?.name } - { requestActivation.requestActivationSubType?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Materiales promocionales: </span>{ requestActivation.promotionalMaterial?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Equipamiento extra: </span>{ requestActivation.equipmentAddon?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Captadores: </span>{ getAllCollaborators() }</Typography>
                    <Typography><CalendarOutlined style={{ marginRight: '5px' }} />{getFormattedDates()}</Typography>
                    {
                        getUqSchedules().map((schedule) => (
                            <Typography key={schedule.id}><ClockCircleOutlined style={{ marginRight: '5px' }}/> {schedule.label}</Typography>
                        ))
                    }
                </div>
            </div>

            <Collapse items={[
                {
                    key: '1',
                    label: <span>Observaciones adicionales</span>,
                    children: (
                        <div style={{ width: '100%'}}>
                            { requestActivation.additionalInfo1 || '- Sin Observaciones -' }
                        </div>
                    )
                }
            ]}
              style={{ marginTop: '15px' }}
            />

            <Collapse items={[
                {
                    key: '1',
                    label: <span>Detalle de la cotización inicial</span>,
                    children: (
                        <div style={{ width: '100%'}}>
                            <Table
                                <Activation>
                                dataSource={requestActivation.activations}
                                pagination={false}
                                size="small"
                                scroll={{ x: 400, y: 'auto' }}
                                rowKey={(record) => { return record.id }}
                                summary={() => (
                                    <Table.Summary fixed>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>Subtotal</Typography>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>
                                                    { Number(requestActivation.requestSubTotalAmount).toFixed(2) + ' ' + requestActivation.currency!.code }
                                                </Typography>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>Impuesto ({getTaxRateFormatted()}%)</Typography>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>
                                                    { Number(requestActivation.requestTaxAmount).toFixed(2) + ' ' + requestActivation.currency!.code }
                                                </Typography>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>Total</Typography>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align="right">
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 550 }}>
                                                    { Number(requestActivation.requestTotalAmount).toFixed(2) + ' ' + requestActivation.currency!.code }
                                                </Typography>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )}
                            >
                                <Column width={320} title="Punto de venta" key="store" render={(row: Activation) => (
                                    <Typography style={{ color: '#707070', fontWeight: 500 }} title={`Dirección: ${row.store!.address}`}>{ row.store!.name }</Typography>
                                )}/>

                                <Column width={115}  align="center" title="Colaboradores" key="numberOfCollaborators" render={(row: Activation) => (
                                    <>{ row.numberOfCollaborators }</>
                                )}/>

                                <Column width={135}  align="center" title="Días de activación" key="dayOfActivation" render={(row: Activation) => (
                                    <>{ row.dates.length }</>
                                )}/>

                                <Column width={180} title="Valor" align="right" key="amount" render={(row: Activation) => (
                                    <>{ Number(row.totalAmount).toFixed(2) + ' ' + requestActivation.currency!.code }</>
                                )}/>
                            </Table>
                        </div>
                    )
                }
            ]} style={{ marginTop: '15px' }}/>

            <Row gutter={24}>
                <Col xs={24} md={24} lg={12} xl={12}  xxl={12} style={{ marginTop: '15px' }}>
                    <Collapse items={[
                        {
                            key: '1',
                            label: <span>Cotización final</span>,
                            children: (
                                <div style={{ width: '100%'}}>
                                    {
                                        (!requestActivation.finalQuoteId && requestActivation.requestActivationStatusId === 'CREATED')  && (
                                            <Dropdown menu={{ items: getFinalQuoteMenuProps() }} trigger={[ 'click' ]}>
                                                <Button loading={loadingQuoteFile}>
                                                    <Space>
                                                        Acciones por ejecutar
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        )
                                    }
                                    {
                                        requestActivation.finalQuoteId && (
                                            <Button loading={loadingQuoteFile} type="default" onClick={downloadQuoteFile}><DownloadOutlined /> Descargar cotización final</Button>
                                        )
                                    }
                                    {
                                        (!requestActivation.finalQuoteId && requestActivation.requestActivationStatusId !== 'CREATED')  && (
                                            <Typography>Sin cargar</Typography>
                                        )
                                    }
                                </div>
                            )
                        }
                    ]} defaultActiveKey={['1']} />
                </Col>

                <Col xs={24} md={24} lg={12} xl={12}  xxl={12}>
                    <Collapse items={[
                        {
                            key: '1',
                            label: <span>Orden de compra</span>,
                            children: (
                                <div style={{ width: '100%'}}>
                                    {
                                        (requestActivation.purchaseOrderDocumentS3Key && requestActivation.requestActivationStatusId === 'PURCHASE_ORDER_TO_REVIEW') && (
                                            <Dropdown menu={{ items: getOcMenuProps() }} trigger={[ 'click' ]}>
                                                <Button loading={loadingOcFile}>
                                                    <Space>
                                                        Ver detalles
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        )
                                    }
                                    {
                                        (requestActivation.purchaseOrderDocumentS3Key && requestActivation.requestActivationStatusId !== 'PURCHASE_ORDER_TO_REVIEW') && (
                                            <Button loading={loadingOcFile} type="default" onClick={downloadOcFile}><DownloadOutlined /> Descargar orden de compra</Button>
                                        )
                                    }
                                    {
                                        !requestActivation.purchaseOrderDocumentS3Key && (
                                            <Typography>Sin cargar</Typography>
                                        )
                                    }
                                </div>
                            )
                        }
                    ]} defaultActiveKey={['1']}  style={{ marginTop: '15px' }} />
                </Col>
            </Row>

        </div>
    );
}

export default AdminGeneralDetailTab;
