import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, message, Modal, Row, Select, Tag} from "antd";
import {Country} from "../../../models/Country";
import {User} from "../../../models/User";
import {UpdateUserFromDashboardRequestDto, UserService} from "../../../services/UserService";
import {
    BankOutlined,
    FlagOutlined, InfoCircleOutlined,
    StarOutlined, TagsOutlined,
    TeamOutlined
} from "@ant-design/icons";
import PhoneInput from "antd-phone-input";
import {ApplicationProfileType} from "../../../models/ApplicationProfileType";
import {Role} from "../../../models/Role";
import {Brand} from "../../../models/Brand";
import {Store} from "../../../models/Store";
import {BrandService} from "../../../services/BrandService";
import {StoreService} from "../../../services/StoreService";
import {FunctionsHelper} from "../../../utils/FunctionsHelper";
import {Permission} from "../../../models/Permission";

interface UserDetailModalProps {
    isOpen: boolean;
    selectedUserId: number;
    handleModalCancel: () => void;
    countries: Country[];
    applicationProfileTypes: ApplicationProfileType[];
    roles: Role[];
    submit: (userId: number, params: UpdateUserFromDashboardRequestDto) => void;
}

const SHOW_ALL_BRANDS = 'SHOW_ALL_REQUEST_ACTIVATIONS_BY_COMPANY';
const SHOW_ALL_STORES = 'SHOW_ALL_REQUEST_ACTIVATIONS_BY_BUSINESS_CHAIN'

function UserDetailModal({ isOpen, selectedUserId, handleModalCancel, countries, applicationProfileTypes, roles, submit }: UserDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<User>();
    const [brands, setBrands] = useState<Brand[]>([]);
    const [stores, setStores] = useState<Store[]>([]);

    useEffect(() => {
        if(!isOpen) {
            form.resetFields();
            setBrands([]);
            setStores([]);
            setCurrentUser(undefined);
        }else {
            fetchUser();
        }
    }, [isOpen]);

    const fetchUser = async () => {
        setLoading(true);

        const userResponse = await UserService.findById(selectedUserId);
        if(userResponse.success) {

            const [
                brandResponse,
                storeResponse
            ] = await Promise.all([
                BrandService.getByCompany(userResponse.data.company?.id || 0),
                StoreService.getByBusinessChain(userResponse.data?.company?.businessChain?.id || 0)
            ]);

            if(brandResponse.success && storeResponse.success) {
                setCurrentUser(userResponse.data);
                setBrands(brandResponse.data);
                setStores(storeResponse.data);

                form.setFieldValue('name', userResponse.data.name);
                form.setFieldValue('lastname', userResponse.data.lastname);
                form.setFieldValue('email', userResponse.data.email);
                form.setFieldValue('cargo', userResponse.data.cargo);
                let newPhone = {...form.getFieldValue('phone'), countryCode: parseInt(userResponse.data.dialCode), areaCode: null, phoneNumber: null};

                if(userResponse.data.phone) {
                    newPhone.areaCode = userResponse.data.phone;
                    // newPhone.phoneNumber = userResponse.data.phone;
                }

                form.setFieldValue('phone', newPhone);

                const storeIds = (userResponse.data.stores || []).map((store) => store.id);
                const brandIds = (userResponse.data.brands || []).map((brand) => brand.id);

                form.setFieldValue('stores', storeIds);
                form.setFieldValue('brands', brandIds);

                setLoading(false);
            }else {
                if(!brandResponse.success) {
                    messageApi.error(brandResponse.data.message as string || 'Hubo un error al intentar obtener las marcas asociadas al usuario, por favor inténtalo nuevamente.', 3.5);
                }

                if(!storeResponse.success) {
                    messageApi.error(storeResponse.data.message as string || 'Hubo un error al intentar obtener las tiendas asociadas al usuario, por favor inténtalo nuevamente.', 3.5);
                }
            }
        }else {
            const error = userResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos del usuario, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const onFinish = async (values: any) => {
        if(currentUser) {
            setLoading(true);
            await submit(currentUser.id, {
                name: values.name,
                lastname: values.lastname,
                email: values.email.toLowerCase(),
                dialCode: `+${values.phone.countryCode}`,
                phone: `${values.phone.areaCode}${values.phone.phoneNumber}`,
                storeIds: values.stores,
                brandIds: values.brands,
                cargo: values.cargo
            });
            setLoading(false);
        }
    }

    const findCountryByCode = (code: string) => {
        return countries.find((record) => {
            return record.code === code;
        });
    }

    const findApplicationProfileTypeById = (id: string) => {
        return applicationProfileTypes.find((record) => {
            return record.id === id;
        });
    }

    const isAssociatedToBusinessChain = () => {
        if(!currentUser) {
            return false;
        }

        const userTypeSelected = currentUser.roles.find((record) => {
            return record.applicationProfileTypeId === 'BUSINESS_CHAIN';
        });

        return !!userTypeSelected;
    }

    const showBrands = () => {
        return (currentUser?.roles || []).filter((record) => {
            return record.applicationProfileTypeId === 'CLIENT';
        }).length > 0;
    }

    const showStores = () => {
        return (currentUser?.roles || []).filter((record) => {
            return record.applicationProfileTypeId === 'BUSINESS_CHAIN';
        }).length > 0;
    }

    const hasPermission = (permission: string) => {
        if(currentUser?.roles && currentUser?.roles.length > 0) {
            let allPermissions: Permission[] = [];

            for (const rol of currentUser?.roles) {
                const rolCheck = roles.find((row) => row.id === rol.id);
                if(rolCheck && rolCheck.permissions && rolCheck.permissions.length > 0) {
                    allPermissions = allPermissions.concat(rolCheck.permissions);
                }
            }

            return allPermissions.map((record) => {
                return record.code;
            }).includes(permission);
        }

        return false;
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={<span>Detalles del usuario - ID: {selectedUserId}{currentUser && currentUser.userType === 'SUPER_ADMIN' ? <Tag style={{ marginLeft: '10px' }} icon={<StarOutlined />} color="processing">Super usuario</Tag> : ''}</span>}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={640}
                loading={loading}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="País"
                            >
                                <Input prefix={<FlagOutlined />} placeholder="País" readOnly disabled={loading} type="text" value={findCountryByCode(currentUser?.company?.countryCode || '') ? findCountryByCode(currentUser?.company?.countryCode || '')?.name : ''}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo de usuario"
                            >
                                <Input prefix={<TagsOutlined />} placeholder="Tipo de usuario" readOnly disabled={loading} type="text" value={findApplicationProfileTypeById((currentUser?.roles.length === 0 ? '' : currentUser?.roles[0].applicationProfileTypeId) || '') ? findApplicationProfileTypeById((currentUser?.roles.length === 0 ? '' : currentUser?.roles[0].applicationProfileTypeId) || '')?.title : ''}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Rol"
                            >
                                <Input prefix={<TeamOutlined />} placeholder="Rol" readOnly disabled={loading} type="text" value={currentUser ? currentUser.roles.map((record) => {
                                    return record.title
                                }).join(', ') : ''}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={isAssociatedToBusinessChain() ? 'Cadena' : 'Compañía'}
                            >
                                <Input prefix={<BankOutlined />} readOnly disabled={loading} type="text" value={currentUser?.company?.name}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        showBrands() && (
                            <Row gutter={24}>
                                <Col xs={24}>
                                    {
                                        !hasPermission(SHOW_ALL_BRANDS) && (
                                            <Form.Item
                                                name="brands"
                                                label="Marcas"
                                            >
                                                <Select
                                                    options={brands.map((record) => {
                                                        return {
                                                            value: record.id,
                                                            label: record.name
                                                        };
                                                    })}
                                                    placeholder="-Seleccione-"
                                                    disabled={loading}
                                                    allowClear
                                                    mode="multiple"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        FunctionsHelper.normalizeText((option?.label ?? '')).includes(FunctionsHelper.normalizeText(input))
                                                    }
                                                />
                                            </Form.Item>
                                        )
                                    }

                                    {
                                        hasPermission(SHOW_ALL_BRANDS) && (
                                            <Form.Item
                                                label="Marcas"
                                            >
                                                <Input type="text" defaultValue="Acceso a todas las marcas de la compañía" readOnly prefix={<InfoCircleOutlined />}/>
                                            </Form.Item>
                                        )
                                    }
                                </Col>
                            </Row>
                        )
                    }

                    {
                        showStores() && (
                            <Row gutter={24}>
                                <Col xs={24}>
                                    {
                                        !hasPermission(SHOW_ALL_STORES) && (
                                            <Form.Item
                                                name="stores"
                                                label="Tiendas"
                                            >
                                                <Select
                                                    options={stores.map((record) => {
                                                        return {
                                                            value: record.id,
                                                            label: record.name
                                                        };
                                                    })}
                                                    placeholder="-Seleccione-"
                                                    disabled={loading}
                                                    allowClear
                                                    mode="multiple"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        FunctionsHelper.normalizeText((option?.label ?? '')).includes(FunctionsHelper.normalizeText(input))
                                                    }
                                                />
                                            </Form.Item>
                                        )
                                    }
                                    {
                                        hasPermission(SHOW_ALL_STORES) && (
                                            <Form.Item
                                                label="Tiendas"
                                            >
                                                <Input type="text" defaultValue="Acceso a todas las tiendas de la cadena" readOnly prefix={<InfoCircleOutlined />}/>
                                            </Form.Item>
                                        )
                                    }
                                </Col>
                            </Row>
                        )
                    }

                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="name"
                                label="Nombres"
                                rules={[{ required: true, message: 'Debes de ingresar los nombres.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los nombres"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="lastname"
                                label="Apellidos"
                                rules={[{ required: true, message: 'Debes de ingresar los apellidos.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese los apellidos" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="email"
                                label="Correo"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Debes de ingresar un correo válido",
                                    }
                                ]}
                            >
                                <Input type="email" disabled={loading} placeholder="Ingrese el correo"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="phone"
                                label="Teléfono"
                                rules={[
                                    { required: true, message: 'El teléfono es requerido' },
                                    {
                                        validator: (_, value) => {
                                            if (value && value.valid()) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Debes ingresar un número de teléfono válido.'));
                                        },
                                    },
                                ]}
                            >
                                <PhoneInput enableSearch searchNotFound="No se encontró el país" searchPlaceholder="Buscar país" allowClear placeholder="Ingrese el teléfono" disabled={loading}/>
                            </Form.Item>
                        </Col>
                    </Row>
                        
                    <Row gutter={24}>
                        <Col xs={24}>
                            <Form.Item
                                name="cargo"
                                label="Cargo"
                                rules={[{ required: true, message: 'Debes de ingresar el Cargo .' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el cargo"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} disabled={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default UserDetailModal;
