import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    EyeOutlined,
    FilterOutlined,
    FormOutlined,
    MoreOutlined
} from "@ant-design/icons";
import {Button, Card, Dropdown, Input, MenuProps, message, Modal, Table, TableProps} from "antd";
import {SearchProps} from "antd/es/input";
import {RequestActivation} from "../../../models/RequestActivation";
import Column from "antd/es/table/Column";
import {TableParams} from "../../../models/TableParams";
import {PAGE_SIZE} from "../../../config/Constants";
import {RequestActivationService} from "../../../services/RequestActivationService";
import {PaginationData} from "../../../models/PaginationData";
import moment from "moment/moment";
import {RequestActivationStatus} from "../../../models/RequestActivationStatus";
import {Contact} from "../../../models/Contact";
import {ContactService} from "../../../services/ContactService";
import BcRequestActivationDetailModal from "./components/BcRequestActivationDetailModal/BcRequestActivationDetailModal";

import './BusinessChainRequestHistory.scss';

const { Search } = Input;

function ActivationHistory() {
    const [modal, contextModalHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<RequestActivation[]>([]);
    const [requestActivationStatuses, setRequestActivationStatuses] = useState<RequestActivationStatus[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: 'createdAt',
        sortOrder: 'descend'
    });

    const [term, setTerm] = useState('');
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<RequestActivation | undefined>(undefined);
    const [contacts, setContacts] = useState<Contact[]>([]);

    const [isRefusedByBusinessChainStatusModalOpen, setIsRefusedByBusinessChainStatusModalOpen] = useState(false);
    const [detailCallback, setDetailCallback] = useState< () =>void | undefined>();

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, term, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        init();

    }, []);

    const init = async () => {
        const [
            requestActivationStatusResponse,
            contactResponse
        ] = await Promise.all([
            RequestActivationService.getAllRequestActivationStatuses(),
            ContactService.getByCurrentCompany(),
            fetchData()
        ]);

        if(requestActivationStatusResponse.success && contactResponse.success) {
            setRequestActivationStatuses(requestActivationStatusResponse.data);
            setContacts(contactResponse.data);
            setLoading(false);
        }else {
            if(!contactResponse.success) {
                const error = contactResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los contactos, por favor inténtalo nuevamente.', 3.5);
            }

            if(!requestActivationStatusResponse.success) {
                const error = requestActivationStatusResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los estados de las activaciones, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);


        const datasourceResponse = await RequestActivationService.getAllByCurrentUserAndSelectedBusinessChain({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: term || undefined
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data as PaginationData<RequestActivation>;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<RequestActivation>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const findStatusById = (id: string) => {
        return requestActivationStatuses.find((record) => {
            return record.id === id;
        });
    }

    const getDropdownMenu = (row: RequestActivation): MenuProps['items'] => {
        if(['IN_VALIDATION'].includes(row.requestActivationStatusId)) {
            return [
                {
                    key: '1',
                    type: 'group',
                    label: 'Acciones'
                },
                {
                    key: '2',
                    label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver solicitud</span>,
                    onClick: () => { showDetailModalOpen(row); }
                },
                {
                    key: '3',
                    type: "divider"
                },
                {
                    key: '4',
                    type: 'group',
                    label: 'Estado actual'
                },
                {
                    key: '5',
                    disabled: true,
                    label: (
                        <span
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <span
                                style={{
                                    width: 6,
                                    height: 6,
                                    borderRadius: '50%',
                                    backgroundColor: row.requestActivationStatus?.alternativeColor,
                                    display: 'inline-block',
                                    marginRight: 8,
                                }}
                            ></span>
                            {row.requestActivationStatus?.alternativeName}
                    </span>
                    )
                },
                {
                    key: '6',
                    type: "divider"
                },
                {
                    key: '7',
                    type: 'group',
                    label: 'Mover a'
                },
                {
                    key: '9',
                    label: (
                        <span
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                                    <span
                                        style={{
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            backgroundColor: findStatusById('REFUSED_BY_BUSINESS_CHAIN')!.alternativeColor,
                                            display: 'inline-block',
                                            marginRight: 8,
                                        }}
                                    ></span>
                            {findStatusById('REFUSED_BY_BUSINESS_CHAIN')!.alternativeName}
                        </span>
                    ),
                    onClick: () => { showRefusedByBusinessChainStatusModalOpen(row); }
                }
            ];
        }
        else {
            return [
                {
                    key: '1',
                    label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver solicitud</span>,
                    onClick: () => { showDetailModalOpen(row); }
                },
            ];
        }
    }

    const showDetailModalOpen = (row: RequestActivation) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const closeDetailModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const showRefusedByBusinessChainStatusModalOpen = (row: RequestActivation, callback?: () => void) => {
        setSelectedRow(row);
        setIsRefusedByBusinessChainStatusModalOpen(true);
        if(callback) {
            setDetailCallback(() => callback);
        }
    }

    const closeRefusedByBusinessChainStatusModalOpen = () => {
        setIsRefusedByBusinessChainStatusModalOpen(false);
    }

    return (
        <Layout breadcrumb={[
            { title: <span><FormOutlined/> Solicitudes</span> },
            { title: 'Historial' }
        ]}
        >
            { contextModalHolder }
            { contextHolder }

            <Card
                loading={loading}
            >

                <div className="filter-container">
                    <label>Filtrar por:</label>
                    <Search placeholder="N° de solicitud, marca o cliente" onSearch={onSearch} disabled={tableLoading} allowClear />
                    <Button type="primary" style={{ float: 'left' }} disabled={tableLoading}><FilterOutlined /></Button>
                </div>
            </Card>

            <Card
                style={{ marginTop: '18px' }}
                loading={loading}
            >
                <Table
                    <RequestActivation>
                    dataSource={dataSource}
                    bordered
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ x: 'auto' }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column title="N° Solicitud" dataIndex="id" key="id"/>

                    <Column title="Marca" key="brand" render={(row: RequestActivation) => (
                        <>{row.brand?.name}</>
                    )} sorter={true}/>

                    <Column title="Cliente" key="company" render={(row: RequestActivation) => (
                        <>{row.company?.name}</>
                    )} sorter={true}/>

                    <Column title="Fecha de solicitud" key="createdAt" render={(row: RequestActivation) => (
                        <span title={moment(row.createdAt).format('DD/MM/YYYY hh:mm A')}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm A')}</span>
                    )} sorter={true} defaultSortOrder="descend"/>

                    <Column title="Estado" key="status" render={(row: RequestActivation) => (
                        <span
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                                <span
                                    style={{
                                        width: 6,
                                        height: 6,
                                        borderRadius: '50%',
                                        backgroundColor: row.requestActivationStatus?.alternativeColor,
                                        display: 'inline-block',
                                        marginRight: 8,
                                    }}
                                ></span>
                            {row.requestActivationStatus?.alternativeName}
                        </span>
                    )} />

                    <Column width={120} align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Card>

            {/* Modals */}
            {
                selectedRow && (
                    <BcRequestActivationDetailModal
                        isOpen={isDetailModalOpen}
                        handleModalCancel={closeDetailModalOpen}
                        selectedRequestActivation={selectedRow}
                        contacts={contacts}
                        requestActivationStatuses={requestActivationStatuses}
                        showRefusedByBusinessChainStatusModalOpen={showRefusedByBusinessChainStatusModalOpen}
                    />
                )
            }
        </Layout>
    );
}

export default ActivationHistory;
