import React, {useEffect} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import GuestRoute from './components/GuestRoute';
import PrivateRoute from './components/PrivateRoute';
import Home from '../scenes/Home/Home';
import NotFound from '../scenes/NotFound/NotFound';
import {User} from "../models/User";
import {useDispatch, useSelector} from "react-redux";
import {MenuType} from "../config/Config";
import DynamicViewer from "../scenes/DynamicViewer/DynamicViewer";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import dashboardMenu from "../config/DashboardMenu";
import MenuAction from "../redux/actions/menuActions/Actions";
import ActivationRequest from "../scenes/Activations/ActivationRequest/ActivationRequest";
import ActivationHistory from "../scenes/Activations/ActivationHistory/ActivationHistory";
import BusinessChainRequestHistory
    from "../scenes/BusinessChainRequests/BusinessChainRequestHistory/BusinessChainRequestHistory";
import ActivationTurns from "../scenes/Activations/ActivationTurns/ActivationTurns";
import Requests from "../scenes/Requests/Requests";
import SignIn from "../scenes/SignIn/SignIn";
import PasswordRecovery from "../scenes/PasswordRecovery/PasswordRecovery";
import UserManagement from "../scenes/UserManagement/UserManagement";
import ClientOperationReports from "../scenes/ClientOperationReports/ClientOperationReports";
import Client from "../scenes/Settings/Client/Client";
import OperationReports from "../scenes/Settings/OperationReports/OperationReports";

export default function AppRoutes() {
    const location = useLocation();
    const dispatch = useDispatch();
    let protectedFirstRoute = "/home";
    const user: User = useSelector((state: any) => state.auth);
    const { routes: menusApp } = dashboardMenu(user);
    const menus = user.allowedMenus.filter((menu) => {
        return [
            MenuType.STANDARD_MENU as string,
            MenuType.IFRAME_VIEWER as string,
            MenuType.PARENT_MENU as string
        ].includes(menu.menuTypeId);
    });

    if (menus.length > 0) {
        const firstMenu = menus[0];
        if (firstMenu) {
            if (firstMenu.menuTypeId === MenuType.STANDARD_MENU && firstMenu.redirectLink) {
                protectedFirstRoute = firstMenu.redirectLink;
            }

            if (firstMenu.menuTypeId === MenuType.IFRAME_VIEWER && firstMenu.redirectLink) {
                protectedFirstRoute = `/dynamic-viewer/${firstMenu.code}`;
            }

            if (firstMenu.menuTypeId === MenuType.PARENT_MENU) {
                const childrenMenus = firstMenu.menus.filter((menu) => {
                    return [
                        MenuType.STANDARD_MENU as string,
                        MenuType.IFRAME_VIEWER as string
                    ].includes(menu.menuTypeId);
                });
                if(childrenMenus.length > 0) {
                    const firstChildrenMenu = childrenMenus[0];

                    if(firstChildrenMenu.menuTypeId === MenuType.STANDARD_MENU && firstChildrenMenu.redirectLink) {
                        protectedFirstRoute = firstChildrenMenu.redirectLink;
                    }

                    if(firstChildrenMenu.menuTypeId === MenuType.IFRAME_VIEWER && firstChildrenMenu.redirectLink) {
                        protectedFirstRoute = `/dynamic-viewer/${firstChildrenMenu.code}`;
                    }
                }
            }
        }
    }

    useEffect(() => {
        const localMenus = FunctionsHelper.flattenMenuArrayDashboardMenu([...menusApp]);
        const localSelectedKey = localMenus.find((row) => {
            const path = (row.path || '').toLowerCase();
            return location.pathname === path;
        });

        if(localSelectedKey) {
            dispatch(MenuAction.update({ selectedKeys: localSelectedKey.keys }));

        }else {
            dispatch(MenuAction.remove());
        }
    }, [location]);

  return (
    <Routes>
        <Route path="/" element={<Navigate to={protectedFirstRoute} />} />
        <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />
        <Route path="/login" element={<GuestRoute outlet={<SignIn />} />} />
        <Route path="/reset-password/:token" element={<GuestRoute outlet={<PasswordRecovery />} />} />
        <Route
            path="/dynamic-viewer/:key"
            element={
                <PrivateRoute
                    outlet={<DynamicViewer />}
                    type={MenuType.IFRAME_VIEWER}
                />
            }
        />
        <Route
            path="/activations/activation-request"
            element={
                <PrivateRoute outlet={<ActivationRequest />}/>
            }
        />
        <Route
            path="/activations/activation-history"
            element={
                <PrivateRoute outlet={<ActivationHistory />}/>
            }
        />
        <Route
            path="/activations/activation-turns"
            element={
                <PrivateRoute outlet={<ActivationTurns />}/>
            }
        />
        <Route
            path="/business-chain/activations/activation-history"
            element={
                <PrivateRoute outlet={<BusinessChainRequestHistory />}/>
            }
        />
        <Route
            path="/requests"
            element={
                <PrivateRoute outlet={<Requests />}/>
            }
        />
        <Route
            path="/user-management"
            element={
                <PrivateRoute outlet={<UserManagement />}/>
            }
        />
        <Route
            path="/client-operation-reports"
            element={
                <PrivateRoute outlet={<ClientOperationReports />}/>
            }
        />
        <Route
            path="/settings/client"
            element={
                <PrivateRoute outlet={<Client />}/>
            }
        />
        <Route
            path="/settings/operation-reports"
            element={
                <PrivateRoute outlet={<OperationReports />}/>
            }
        />
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
