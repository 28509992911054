import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {PaginationData} from "../models/PaginationData";
import {RequestActivation} from "../models/RequestActivation";
import {RequestActivationStatus} from "../models/RequestActivationStatus";
import {RequestActivationType} from "../models/RequestActivationType";

export const RequestActivationService = {
    getAllByCurrentUserAndSelectedCompany: async (bodyParams: GetAllRequestActivationsByCurrentUserAndSelectedCompanyRequestDto): Promise<ApiResponse<PaginationData<RequestActivation>>>  => {
        try {
            const response = await MainBackendAxios.post(`/request-activation/get-all-by-current-user-and-selected-company`, {
                page: bodyParams.page,
                pageSize: bodyParams.pageSize,
                term: bodyParams.term || undefined,
                sortField: bodyParams.sortField,
                sortOrder: bodyParams.sortOrder
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as PaginationData<RequestActivation>,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllByCurrentUserAndSelectedBusinessChain: async (bodyParams: GetAllRequestActivationsByCurrentUserAndSelectedBusinessChainRequestDto): Promise<ApiResponse<PaginationData<RequestActivation>>>  => {
        try {
            const response = await MainBackendAxios.post(`/request-activation/get-all-by-current-user-and-selected-business-chain`, {
                page: bodyParams.page,
                pageSize: bodyParams.pageSize,
                term: bodyParams.term || undefined,
                sortField: bodyParams.sortField,
                sortOrder: bodyParams.sortOrder
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as PaginationData<RequestActivation>,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllByCurrentUserCountry: async (bodyParams: GetAllRequestActivationsByCurrentUserCountryRequestDto): Promise<ApiResponse<PaginationData<RequestActivation>>>  => {
        try {
            const response = await MainBackendAxios.post(`/request-activation/get-all-by-current-user-country-administrative-mode`, {
                page: bodyParams.page,
                pageSize: bodyParams.pageSize,
                term: bodyParams.term || undefined,
                sortField: bodyParams.sortField,
                sortOrder: bodyParams.sortOrder
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as PaginationData<RequestActivation>,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllRequestActivationStatuses: async (): Promise<ApiResponse<RequestActivationStatus[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/request-activation-status/get-all-real-statuses`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as RequestActivationStatus[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllRequestActivationTypes: async (): Promise<ApiResponse<RequestActivationType[]>>  => {
        try {
            const response = await MainBackendAxios.get(`/request-activation-type/get-all`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as RequestActivationType[],
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    createRequestActivation: async (paramBody: CreateRequestActivationRequestDto): Promise<ApiResponse<CreateRequestActivationResponseDto>>  => {
        try {
            const response = await MainBackendAxios.post(`/request-activation/create`, paramBody);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as CreateRequestActivationResponseDto,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findById: async (id: string): Promise<ApiResponse<RequestActivation>> => {
        try {
            const response = await MainBackendAxios.get(`/request-activation/find-by-id/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data as RequestActivation,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateStatusToCancelled: async (id: string, reason: string): Promise<ApiResponse<{ executedAction: boolean }>> => {
        try {
            const response = await MainBackendAxios.put(`/request-activation/update-status-to-cancelled/${id}`, { reason });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateStatusToRefused: async (id: string, reason: string): Promise<ApiResponse<{ executedAction: boolean }>> => {
        try {
            const response = await MainBackendAxios.put(`/request-activation/update-status-to-refused/${id}`, { reason });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateStatusToConfirmed: async (id: string, currencyCode: string, file: File): Promise<ApiResponse<{ executedAction: boolean }>> => {
        try {
            const formData = new FormData();
            formData.append('currencyCode', currencyCode);
            formData.append('file', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await MainBackendAxios.put(`/request-activation/update-status-to-confirmed/${id}`, formData, config);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateStatusToPurchaseOrderToReview: async (id: string, file: File): Promise<ApiResponse<{ executedAction: boolean }>> => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await MainBackendAxios.put(`/request-activation/update-status-to-purchase-order-to-review/${id}`, formData, config);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateStatusToRefusedPurchaseOrder: async (id: string, reason: string): Promise<ApiResponse<{ executedAction: boolean }>> => {
        try {
            const response = await MainBackendAxios.put(`/request-activation/update-status-to-refused-purchase-order/${id}`, { reason });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    updateStatusToApprovedPurchaseOrder: async (id: string): Promise<ApiResponse<{ executedAction: boolean }>> => {
        try {
            const response = await MainBackendAxios.put(`/request-activation/update-status-to-approved-purchase-order/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

export interface GetAllRequestActivationsByCurrentUserAndSelectedCompanyRequestDto {
    page: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;
    term?: string;
    starDate?: string;
    endDate?: string;
    brandId?: number;
    requestActivationStatusIds?: string[];
}

export interface GetAllRequestActivationsByCurrentUserAndSelectedBusinessChainRequestDto {
    page: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;
    term?: string;
    starDate?: string;
    endDate?: string;
    requestActivationStatusIds?: string[];
}

export interface GetAllRequestActivationsByCurrentUserCountryRequestDto {
    page: number;
    pageSize?: number;
    requestActivationStatusIds?: string[];
    startDate?: string;
    endDate?: string;
    term?: string;
    sortOrder?: string,
    sortField?: string;
}

export interface CreateRequestActivationRequestDto {
    brandId: number;
    requiresSpecialDynamic: boolean;
    requestActivationTypeId: number;
    additionalInfo1: string;
    currencyCode: string;
    subTotalAmount: number;
    taxAmount: number;
    taxRate: number;
    totalAmount: number;
    activations: {
        storeId: number;
        dates: string[];
        scheduleId: number;
        numberOfCollaborators: number;
        totalAmount: number;
    }[],
    activationGoalId: number;
    activationServiceId: number;
    requestActivationSubTypeId: number;
    promotionalMaterialId: number | null;
    equipmentAddonId: number | null;
}

export interface CreateRequestActivationResponseDto {
    createdId: string;
}
