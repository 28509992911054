import React, {useEffect, useState} from 'react';
import {Country} from "../../../../models/Country";
import {
    Button,
    Col,
    Divider,
    Form, GetProp,
    Input,
    InputNumber,
    InputNumberProps,
    message,
    Modal,
    Row,
    Select, Upload, UploadProps
} from "antd";
import {ItemInterface} from "../../../../models/ItemInterface";
import TextArea from "antd/es/input/TextArea";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

interface NewClientModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (name: string, countryCode: string, prefix: string, taxes: number, description: string | null, logo: File | null ,razon: string,document: string,typedocument: string) => void;
    countries: Country[];
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function NewClientModal({ isOpen, handleModalCancel, submit, countries }: NewClientModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [countryCodeSelected, setCountryCodeSelected] = useState<string>();
    const [documentCodeSelected, setDocumentCodeSelected] = useState<string>();

    const [imageUrl, setImageUrl] = useState<string>();
    const [imageFile, setImageFile] = useState<FileType | null>(null);

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
            setCountryCodeSelected(undefined);
            setImageUrl(undefined);
            setImageFile(null);
        }
    }, [isOpen]);

    const getCountries = (): ItemInterface[] => {
        return countries
            .filter((record) => {
                return record.isActive;
            })
            .map((record) => {
                return {
                    value: record.code,
                    label: record.name
                }
            });
    }

    const onFinish = async (values: any) => {

        setLoading(true);

        await submit(
            values.name!.trim(),
            values.country!,
            values.prefix!.trim(),
            (((values.taxes * 100) / 100) / 100),
            values.description ? (values.description.trim() || null) : null,
            imageFile,
            values.razon!.trim(),
            values.document!.trim(),
            values.typedocument
        );

        setLoading(false);
    }

    const onChange: InputNumberProps['onChange'] = (value) => {
        console.log('changed', value);
    };

    const getBase64 = (img: FileType, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puedes cargar archivos tipo JPG o PNG');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('La imagen no puede superar los 2MB.');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        const file = info.file.originFileObj as FileType;

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'removed') {
            setLoading(false);
        }

        if (file && beforeUpload(file)) {
            setImageFile(file);
            getBase64(file, (url) => {
                setImageUrl(url);
                setLoading(false);
            });
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Cargar logo</div>
        </button>
    );

    return (
        <>
            {contextHolder}

            <Modal
                title="Crear cliente"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={640}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '15px' }}>
                        <Col xs={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre del cliente"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="razon"
                                label="Razon Social"
                                rules={[{ required: true, message: 'Debes de ingresar la razon social.' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese la razon social"/>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                        <Form.Item
                                name="typedocument"
                                label="Tipo de Documento"
                                rules={[{ required: true, message: 'Debes de seleccionar un tipo de documento' }]}
                            >
                                <Select
                                    options={[{value:'1',label:'RUT'},{value:'2',label:'DNI'}]}
                                    placeholder="-Seleccione-"
                                    onChange={(v) => {
                                        setDocumentCodeSelected(v);
                                    }}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                        <Form.Item
                                name="document"
                                label="Número de Documento"
                                rules={[{ required: true, message: 'Debes de ingresar un Número Documuento' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese un numero de Documento" maxLength={20}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="country"
                                label="País"
                                rules={[{ required: true, message: 'Debes de seleccionar un país' }]}
                            >
                                <Select
                                    options={getCountries()}
                                    placeholder="-Seleccione-"
                                    onChange={(v) => {
                                        setCountryCodeSelected(v);
                                    }}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="prefix"
                                label="Prefijo"
                                tooltip={'Texto que será usado para dar el formato de los números de solicitud. Ej. Prefijo: WNG, Solicitud: WNG00121'}
                                rules={[{ required: true, message: 'Debes de ingresar un prefijo' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese un prefijo" maxLength={5}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="taxes"
                                label="IVA"
                                rules={[
                                    { required: true, message: 'Debes de ingresar un valor para el IVA' },
                                    {
                                        validator: (_, value) =>
                                            value > 0
                                                ? Promise.resolve()
                                                : Promise.reject(new Error('El valor debe ser mayor a 0'))
                                    }
                                ]}
                            >
                                <InputNumber<number>
                                    style={{ width: '100%' }}
                                    defaultValue={0}
                                    max={100}
                                    step={0.01}
                                    onChange={onChange}
                                    addonAfter={'%'}
                                    placeholder="Ingrese el valor del impuesto"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="description"
                                label="Descripción"
                            >
                                <TextArea rows={4} placeholder="Ingrese una descripción"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewClientModal;
